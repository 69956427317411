<script src="../../store/modules/user.js"></script>
<template>
	<div class="service-view">
		<ByCancel @close="close"></ByCancel>
		<h3 class="title">服務條款</h3>
		<div class="content">
			<div>
				<p>一、認知與接受條款</p>
				<p>
					當您完成點溡平台之會員註冊手續，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容。本平台有權於任何時間修改或變更本約定書之內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用本服務時，皆視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意本約定書的內容，您應立即停止使用本服務。若您為未滿十八歲，除應符合上述規定外，並應於您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後，方得使用或繼續使用。當您使用或繼續使用本服務時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更。
				</p>
				<p>
					本平台所提及之『合約』，係指使用者對於提供予服務之條款、訂單方式與付款方式。
				</p>
				<p>本文中提到的『您』意指所有使用本平台以及任何訂購商品或服務的人。</p>
			</div>
			<div class="part">
				<p>二、與第三人網站之連結</p>
				<p>
					在您瀏覽本平台時，將有機會連結到其他網站，這些網站皆由其自行負責，不屬於本公司控制及負責範圍之內。本條款僅適用於本平台。您應先行閱讀其他網站的隱私權政策再行使用。
				</p>
			</div>
			<div class="part">
				<p>三、會員註冊義務</p>
				<p>為了能使用本服務，您同意以下事項：</p>
				<p>
					依本服務註冊表之提示提供您本人正確資料，且不得以第三人之名義註冊為會員。如有錯誤或不實資料，本公司有權不經事先通知，逕行暫停或中止帳號與本服務之全部或一部份。請定期更新會員個人資料，確保其正確性，以獲取最佳服務。
				</p>
			</div>
			<div class="part">
				<p>四、隱私權政策</p>
				<p>
					關於您的會員註冊以及其他特定資料，均依本公司「隱私權政策」受到保護與規範。
				</p>
			</div>
			<div class="part">
				<p>五、會員帳號、密碼及安全</p>
				<p>
					完成本服務的登記程序之後，您將收到一組密碼及帳號。維持密碼及帳號的機密安全，是您的責任。利用該密碼及帳號所進行的一切行動，您將負完全的責任。您並同意以下事項：
				</p>
				<p>
					(1)您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您應立即通知服務人員。
				</p>
				<p>(2)每次連線完畢，均結束您的帳號使用。</p>
			</div>
			<div class="part">
				<p>六、免責聲明</p>
				<p>
					1.本服務所提供之各項功能，均依該功能當時之現況提供使用，本公司對於其效能、速度、完整性、可靠性、安全性、正確性等，皆不負擔任何明示或默示之擔保責任。
				</p>
				<p>
					2.本網頁所載的所有資料、商標、標誌、圖像、短片、聲音檔案、連結及其他資料等（以下簡稱「資料」），將會隨時更改資料，並由本公司決定而不作另行通知。
				</p>
				<p>
					3.我們已採取謹慎的措施以確保本網站提供的信息為正確無誤，雖本公司已盡力確保
					本網頁的資料準確性，但本公司不會明示或隱含保證該等資料均為準確無誤，亦不會
					對任何錯誤或遺漏承擔責任。本公司不會對使用或任何人士使用本網頁而引致任何損
					害（包括但不限於電腦病毒、系統固障、資料損失）承擔任何賠償。
				</p>
				<p>
					4.您同意免除本網站及任何餐廳/店家因提供的信息錯誤對您所造成之困擾及責任，如
					遇本網站及任何餐廳/店家提供之信息有誤，煩請您不吝於聯繫本公司，我們將盡快調
					整相關錯漏。我們不承擔任何現行法律允許範圍下有關的服務和商品供應之責任。本
					公司將不承擔您可能得到的間接損失，包括利潤等。此責任限制並不適用於人身傷害
					或死亡所產生的直接損失。
				</p>
				<p>
					5.本公司恕不為任何店家的延誤、疏忽，與因不可抗力因素影響造成的延遲或疏忽負
					責。
				</p>
			</div>
			<div class="part">
				<p>七、使用者的守法義務及承諾</p>
				<p>
					您承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
				</p>
				<p>
					1.公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案
				</p>
				<p>
					2.侵害或毀損本公司或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利
				</p>
				<p>3.違反依法律或契約所應負之保密義務</p>
				<p>4.冒用他人名義使用本服務</p>
				<p>5.傳輸或散布電腦病毒</p>
				<p>6.從事未經本公司事前授權的商業行為</p>
				<p>
					7.刊載、傳輸、發送垃圾郵件、連鎖信、違法或未經本公司許可之多層次傳銷訊息及廣告等；或儲存任何侵害他人智慧財產權或違反法令之資料
				</p>
				<p>
					8.對本服務其他用戶或第三人產生困擾、不悅或違反一般網路禮節致生反感之行為
				</p>
				<p>9.從事未經本公司事前授權的商業行為</p>
				<p>
					10.其他不符本服務所提供的使用目的之行為或本公司有正當理由認為不適當之行為
				</p>
			</div>
			<div class="part">
				<p>八、服務內容之變更與電子報及EDM發送</p>
				<p>
					1.會員同意本公司所提供本服務之範圍，本公司均得視業務需要及實際情形，增減、變更或終止相關服務的項目或內容，且無需個別通知會員。
				</p>
				<p>
					2.會員同意本公司得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員。
				</p>
				<p>
					3.會員同意本公司得不定期發送電子報或商品訊息(EDM)至會員所登錄的電子信箱帳號。當會員收到訊息後表示拒絕接受行銷時，本公司將停止繼續發送行銷訊息。
				</p>
			</div>
			<div class="part">
				<p>九、交易行為</p>
				<p>
					1.會員使用本服務進行交易時，應依據本公司所提供之確認商品數量及價格機制進行。
				</p>
				<p>
					2.會員同意使用本服務訂購產品時，於本公司通知確認交易成立前，本公司仍保有不接受訂單或取消出貨之權利。會員向本公司發出訂購通知後，系統將自動發出接受通知，但此通知並非訂單確認通知，關於交易成立與否本公司將另行告知。若因訂單內容之標的商品或服務，其交易條件(包括但不限於規格、內容說明、圖片、)有誤時，本公司仍得於下單後二工作日內拒絕該筆訂單。
				</p>
				<p>
					3.會員若於使用本服務訂購產品後倘任意退換貨、取消訂單、或有任何本公司認為不適當而造成本公司作業上之困擾或損害之行為，本公司將可視情況採取拒絕交易、暫停取消配送付款方式，或永久取消會員資格辦理。會員使用本服務進行交易時，得依照消費者保護法之規定行使權利。因會員之交易行為而對本服務條款產生疑義時，應為有利於消費者之解釋。
				</p>
				<p>
					廠商或個人可能透過本服務或經由宅配通網站連結之其他網站提供商品買賣、服務或其他交易行為。您因前述買賣、服務或其他交易行為所產生之爭執，應向各該廠商或個人尋求救濟或解決之道。宅配通網站聲明絕不介入您與廠商或個人間的任何買賣、服務或其他交易行為，對於您所獲得的商品、服務或其他交易標的物亦不負任何擔保責任。
				</p>
				<p>
					請注意本平台所提供的產品有可能只適合某特定年齡層所使用，您應該要詳細檢查所訂購之產品適合將來使用者。
				</p>
			</div>
			<div class="part">
				<p>十、服務之停止、中斷</p>
				<p>
					本公司將依一般合理之技術及方式，維持系統及服務之正常運作。但於以下各項情況時，本公司有權可以停止、中斷提供本服務：
				</p>
				<p>1.本服務網站電子通信設備進行必要之保養及施工時。</p>
				<p>2.發生突發性之電子通信設備故障時。</p>
				<p>3.本服務網站申請之電子通信服務被停止，無法提供服務時。</p>
				<p>4.系統遭第三人以非法行為入侵和破壞時。</p>
				<p>
					5.因颱風、地震、海嘯、洪水、停電、戰爭、海底電纜斷裂等天災不可抗力之因素，造成線路中斷或毀損或其他不可歸責於本公司致使網站無法提供服務時。
				</p>
			</div>
			<div class="part">
				<p>十一、智慧財產權的保護</p>
				<p>
					本公司所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面排版、網頁設計、會員內容，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，除明確為法律所許可者外，必須依法取得本公司或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
				</p>
			</div>
			<div class="part">
				<p>十二、拒絕或終止您的使用</p>
				<p>
					您同意本公司得基於其自行之考量，因任何理由，或本公司認為您已經違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除。本公司亦得依其自行之考量，隨時終止本服務或其任何部分，如牽涉您於本服務尚有殘留幣額或紅利積點等虛擬貨幣時，本公司將事先通知即將關閉或刪除您的帳號及您帳號中所有相關資料及檔案，並告知殘留餘額之處理方式，以停止您於本服務之使用。此外，您同意若本服務之使用被終止，本公司對您或任何第三人均不承擔責任。
				</p>
			</div>
			<div class="part">
				<p>十三、您對本服務之授權</p>
				<p>
					若使用者參加本平台合作夥伴(第三者)之活動，本服務會主動告知將使用者資料提供於合作夥伴(第三者)產品或服務名單內，倘若會員不同意將其資料列於合作夥伴(第三者)產品或服務名單內，可以通知本服務，於名單中刪除其資料，同時放棄其本服務以外之優惠或獲獎權利。
					對於會員所登錄或留存之個人資料，會員同意本服務網站得於合理之範圍內蒐集、處理及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。
					若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本服務。任何資料一經您上載、傳送、輸入或提供至本服務時，視為您已允許本服務無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，您對此絕無異議。您並應保證本服務使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本公司負損害賠償責任。
				</p>
			</div>
			<div class="part">
				<p>十四、會員授權</p>
				<p>
					對於會員上載、傳送、輸入或提供之資料，會員同意本公司得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。若會員無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本公司。任何資料一經會員上載、傳送、輸入或提供至本公司時，視為會員已允無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，會員對此絕無異議。會員並應保證本公司使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
				</p>
			</div>
			<div class="part">
				<p>十五、Cookie之使用</p>
				<p>
					為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致網站某些功能無法正常執行
					。
				</p>
			</div>
			<div class="part">
				<p>十六、準據法與管轄法院</p>
				<p>
					本約定書之解釋與適用，以及與本約定書有關的爭議，均以中華民國法律為準據法，並以台灣台中地方法院為第一審管轄法院。
				</p>
			</div>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	methods: {
		close() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="sass" scoped>
.service-view
  padding-bottom: 18px

.title
  position: absolute
  top: -14px
  left: 50%
  transform: translateX(-50%)

.content
  +fc
  flex-direction: column
  padding: 0 17px 30px
  margin-top: 15px
  width: 100%
  letter-spacing: 1px
  font-size: 15px
  line-height: 1.5
  text-align: justify

  > p
    width: 100%

.part
  width: 100%
  margin: 25px 0 0
</style>
